<template>
  <div class="main-content">
    <h3 class="marginBottom20">订单详情</h3>
    <div
      style="display: flex;align-items: center;justify-content: space-between;"
    >
      <el-button @click="goBack()" style="margin-bottom:20px">返回</el-button>
      <el-button
        type="primary"
        v-if="meun.state !== 1 && meun.state !== 9 && meun.state !== 10"
        @click="handleLook"
        style="margin-bottom:20px"
        >查看轨迹</el-button
      >
    </div>

    <el-card>
      <el-descriptions title="" :column="1">
        <el-descriptions-item
          label="订单编号"
          :labelStyle="{ width: '140px' }"
          >{{ meun.code }}</el-descriptions-item
        >
        <el-descriptions-item
          label="订单状态"
          :labelStyle="{ width: '140px' }"
          >{{ meun.stateText }}</el-descriptions-item
        >
      </el-descriptions>
    </el-card>
    <el-card style="margin-top:20px">
      <el-descriptions title="订单信息" :column="1">
        <el-descriptions-item
          label="会员编号"
          :labelStyle="{ width: '140px' }"
          >{{ meun.userCode }}</el-descriptions-item
        >
        <el-descriptions-item
          label="会员昵称"
          :labelStyle="{ width: '140px' }"
          >{{ meun.nickname }}</el-descriptions-item
        >
        <el-descriptions-item
          label="会员等级"
          :labelStyle="{ width: '140px' }"
          >{{ meun.levelName }}</el-descriptions-item
        >
        <el-descriptions-item label="手机号" :labelStyle="{ width: '140px' }">{{
          meun.phone
        }}</el-descriptions-item>
        <el-descriptions-item
          label="下单时间"
          :labelStyle="{ width: '140px' }"
          >{{ meun.createTime }}</el-descriptions-item
        >
      </el-descriptions>
    </el-card>
    <el-card style="margin-top:20px">
      <el-descriptions title="车辆信息" :column="1">
        <el-descriptions-item
          label="车辆编号"
          :labelStyle="{ width: '140px' }"
          >{{ meun.carCode }}</el-descriptions-item
        >
        <el-descriptions-item
          label="车型名称"
          :labelStyle="{ width: '140px' }"
          >{{ meun.carTypeName }}</el-descriptions-item
        >
        <el-descriptions-item
          label="所属区域"
          :labelStyle="{ width: '140px' }"
          >{{ meun.regionName }}</el-descriptions-item
        >
      </el-descriptions>
    </el-card>
    <el-card style="margin-top:20px">
      <el-descriptions title="押金信息" :column="1">
        <el-descriptions-item
          label="所需押金（元）"
          :labelStyle="{ width: '140px' }"
          >{{ meun.depositPrice }}</el-descriptions-item
        >
        <el-descriptions-item
          label="支付渠道"
          :labelStyle="{ width: '140px' }"
          >{{ meun.payType }}</el-descriptions-item
        >
        <el-descriptions-item
          label="实缴押金（元）"
          :labelStyle="{ width: '140px' }"
          >{{ meun.depositPrice }}</el-descriptions-item
        >
      </el-descriptions>
    </el-card>
    <el-card style="margin-top:20px">
      <el-descriptions title="订单金额" :column="1">
        <el-descriptions-item
          label="订单金额（元）"
          :labelStyle="{ width: '140px' }"
          >{{ meun.packagePrice }}</el-descriptions-item
        >
        <el-descriptions-item
          label="会员等级减免（元）"
          :labelStyle="{ width: '140px' }"
          ><span v-if="meun.memberPrice > 0">-</span
          >{{ meun.memberPrice }}</el-descriptions-item
        >
        <el-descriptions-item
          label="优惠券减免（元）"
          :labelStyle="{ width: '140px' }"
          ><span v-if="meun.couponPrice > 0">-</span
          >{{ meun.couponPrice }}</el-descriptions-item
        >
        <el-descriptions-item
          label="实付金额（元）"
          :labelStyle="{ width: '140px' }"
          >{{ meun.payPrice }}</el-descriptions-item
        >
      </el-descriptions>
    </el-card>

    <avue-crud :data="tableData" :option="option"> </avue-crud>
  </div>
</template>

<script>
import { manageView } from "@/api/order/index";
export default {
  name: "index",
  data() {
    return {
      meun: {},
      tableData: [],
      option: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "套餐名称",
            prop: "packageName",
          },
          {
            label: "套餐金额(元)",
            prop: "packagePrice",
          },
          {
            label: "会员等级减免(元)",
            prop: "memberPrice",
            formatter: function(row, column, cellValue) {
              return cellValue > 0 ? "-" + cellValue : cellValue;
            },
          },
          {
            label: "优惠券减免(元)",
            prop: "couponPrice",
            formatter: function(row, column, cellValue) {
              return cellValue > 0 ? "-" + cellValue : cellValue;
            },
          },
          {
            label: "实付金额(元)",
            prop: "amount",
          },
          {
            label: "支付渠道",
            prop: "payType",
            formatter: function(row, column, cellValue) {
              return cellValue === '1'
                ? "微信"
                : cellValue === '2'
                ? "余额"
                : cellValue;
            },
          },
          {
            label: "套餐时长(分钟)",
            prop: "packageDuration",
          },
          {
            label: "使用时长(分钟)",
            prop: "userTime",
          },
        ],
      },
    };
  },
  mounted() {
    this.$route.query.id && this.getView();
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 查看轨迹
    handleLook() {
      this.$router.push({
        path: "/order/manage/look",
        query: {
          id: this.meun.id,
        },
      });
    },
    getView() {
      this.loading = true;
      manageView({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          switch (res.data.state) {
            case 1:
              res.data.stateText = "待支付";
              break;
            case 2:
              res.data.stateText = "进行中";
              break;
            case 3:
              res.data.stateText = "已完成";
              break;
            case 4:
              res.data.stateText = "售后中";
              break;
            case 5:
              res.data.stateText = "已关闭-正常关闭";
              break;
            case 6:
              res.data.stateText = "已关闭-拒绝售后";
              break;
            case 7:
              res.data.stateText = "已关闭-退款成功";
              break;
            case 8:
              res.data.stateText = "已关闭-换车成功";
              break;
            case 9:
              res.data.stateText = "已关闭-超时未支付";
              break;
            case 10:
              res.data.stateText = "已关闭-手动取消";
              break;
            default:
          }
          switch (res.data.payType) {
            case 1:
              res.data.payType = "微信";
              break;
            case 2:
              res.data.payType = "余额";
              break;
            default:
          }
          this.meun = res.data;
          this.tableData = res.data.packages;
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
